.not-found-wrapper .links-container .title, .not-found-wrapper .heading .title {
  font-family: "factoria", "open-sans", Georgia, serif;
}

.not-found-wrapper .links-container .title, .not-found-wrapper .heading .title {
  font-family: "factoria", "open-sans", Georgia, serif;
}

.page-main {
  margin-top: 0;
}

.not-found-wrapper {
  background: url("../../images/articles/404/1.png") no-repeat top center #3D3B3B;
  background-size: cover;
  padding: 0;
  margin-top: 2rem;
}
.not-found-wrapper .heading {
  padding: 2rem 1rem 0;
  color: #ffffff;
}
.not-found-wrapper .heading .title {
  font-size: 30px;
  margin: 0;
}
.not-found-wrapper .links-container {
  background-color: #3D3B3B;
  color: #ffffff;
  padding: 1.5rem 1rem;
  margin-top: 1rem;
  border-top: 5px solid #f9c01a;
}
.not-found-wrapper .links-container .title {
  margin: 0 0 0.5rem;
  font-size: 18px;
}
.not-found-wrapper .links-wrapper span {
  display: block;
}
.not-found-wrapper .links-wrapper .link {
  display: inline-block;
  margin: 0.25rem 2rem 1rem 0;
  color: #f9c01a;
  text-decoration: underline;
}

@media screen and (min-width: 48em) {
  .not-found-wrapper {
    padding: 10rem 0;
  }
  .not-found-wrapper .heading {
    padding: 0 2rem;
  }
  .not-found-wrapper .heading .title.top {
    font-size: 4.2857142857rem;
  }
  .not-found-wrapper .links-container {
    padding: 1.5rem 2rem;
    position: relative;
    width: 80%;
  }
  .not-found-wrapper .links-container:after {
    content: "";
    position: absolute;
    border-bottom: 0 solid transparent;
    border-top: 9.9285714286rem solid transparent;
    border-left: 9.9285714286rem solid #3D3B3B;
    right: -9.9285714286rem;
    top: 0;
  }
  .not-found-wrapper .links-wrapper {
    margin: 1rem 0 1.5rem;
  }
  .not-found-wrapper .links-wrapper span {
    display: inline-block;
    margin-right: 1rem;
  }
  .not-found-wrapper .links-wrapper .link {
    margin-bottom: 0;
  }
  .not-found-wrapper .links-wrapper:last-of-type {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 64em) {
  .not-found-wrapper .links-container, .not-found-wrapper .heading {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .not-found-wrapper {
    margin-top: 3rem;
    background-size: initial;
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .not-found-wrapper .heading .title {
    font-size: 2.5714285714rem;
  }
  .not-found-wrapper .heading .title.top {
    font-size: 5.3571428571rem;
  }
  .not-found-wrapper .links-container:after {
    border-top-width: 10.7857142857rem;
    border-left-width: 10.7857142857rem;
    right: -10.7857142857rem;
  }
  .not-found-wrapper .links-container .title {
    font-size: 1.7142857143rem;
  }
  .not-found-wrapper .links-wrapper {
    font-size: 1.1428571429rem;
  }
}