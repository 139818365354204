// CompAndSave.com custom style
// Author: Nelly Goh
//Dependencies
@import "mixins/mixins";
@import "mixins/divider";
@import "mixins/tooltip";
@import "mixins/icons";
@import "mixins/button";
@import "mixins/toggle-view";
@import "mixins/list";
@import "mixins/accordion-panel";
@import "mixins/file-uploader";
@import "mixins/progress-bar";
@import "mixins/callout";
@import "mixins/container";
@import "mixins/coupon-box";
@import "mixins/video";


%work-sans, %sec-font {
    font-family: $sec-font;
}

%underline-links{
    border: 0;
    color: $medium-gray;
    font-weight: normal;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}
