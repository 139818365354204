@import '../../../styles/settings';
@import '../../../styles/ci/mixins';

%sec-font {
    font-family: $sec-font;
}
.page-main {
    margin-top: 0;
}
.not-found-wrapper {
    background: url('../../images/articles/404/1.png') no-repeat top center $dark-gray;
    background-size: cover;
    padding: 0;
    margin-top: 2rem;
    .heading {
        padding: 2rem 1rem 0;
        color: $white;
        .title {
            @extend %sec-font;
            font-size: 30px;
            margin: 0;
        }

    }
    .links-container {
        background-color: $dark-gray;
        color: $white;
        padding: 1.5rem 1rem;
        margin-top: 1rem;
        border-top: 5px solid get-color('yellow');
        .title {
            @extend %sec-font;
            margin: 0 0 0.5rem;
            font-size: 18px;
        }
    }
    .links-wrapper {
        span {
            display: block;
        }
        .link {
            display: inline-block;
            margin: 0.25rem 2rem 1rem 0;
            color: get-color('yellow');
            text-decoration: underline;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .not-found-wrapper {
        padding: 10rem 0;
        .heading {
            padding: 0 2rem;
            .title {
                &.top {
                    font-size: rem-calc(60);
                }
            }
        }
        .links-container {
            padding: 1.5rem 2rem;
            position: relative;
            width: 80%;
            &:after {
                content: "";
                position: absolute;
                border-bottom: 0 solid transparent;
                border-top: rem-calc(139) solid transparent;
                border-left: rem-calc(139) solid #3D3B3B;
                right: rem-calc(-139);
                top: 0;
            }
        }
        .links-wrapper {
            margin: 1rem 0 1.5rem;
            span {
                display: inline-block;
                margin-right: 1rem;
            }
            .link {
                margin-bottom: 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    %paddinglr {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .not-found-wrapper {
        margin-top: 3rem;
        background-size: initial;
        padding-top: 15rem;
        padding-bottom: 15rem;
        .heading {
            @extend %paddinglr;
            .title {
                font-size: rem-calc(36);
                &.top {
                    font-size: rem-calc(75);
                }
            }
        }
        .links-container{
            @extend %paddinglr;
            &:after {
                border-top-width: rem-calc(151);
                border-left-width: rem-calc(151);
                right: rem-calc(-151);
            }
            .title {
                font-size: rem-calc(24);
            }
        }
        .links-wrapper {
            font-size: rem-calc(16);
        }
    }
}
